<template>
  <el-form :inline="true" :model="formInline" class="demo-form-inline">
    <el-form-item label="顾客姓名">
      <el-input v-model="formInline.name" size="small" placeholder="请输入顾客姓名" clearable />
    </el-form-item>
    <el-form-item label="业务员">
      <el-select size="small"
                 multiple
                 v-model="formInline.ywyid"
                 placeholder="请选择业务员"
                 clearable>
        <el-option v-for="item in ywy" :label="item.ywyname" :key="item.id" :value="item.id" />
      </el-select>
    </el-form-item>
    <el-form-item label="提交时间">
      <el-date-picker
                      v-model="formInline.date"
                      type="daterange"
                      range-separator="到"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      size="small"
                      clearable />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="small" @click="onSubmit">查询</el-button>
    </el-form-item>
  </el-form>
  <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
    <el-tab-pane label="等待初审" name="0">
    </el-tab-pane>
    <el-tab-pane label="已初审" name="1">
    </el-tab-pane>
  </el-tabs>
  <el-table :data="tableData" border v-loading="loading" element-loading-text="加载中..." style="width: 100%">
    <el-table-column fixed prop="name" label="顾客姓名" width="100" />
    <el-table-column prop="phone" label="顾客手机号" width="100" />
    <el-table-column prop="identity" label="身份证号码" width="100" />
    <el-table-column prop="handling" label="办理机型" width="100" />
    <el-table-column prop="machine" label="机价" width="100" />
    <el-table-column prop="sesame" label="芝麻分" width="100" />
    <el-table-column prop="mobile" label="手机年限" width="100" />
    <el-table-column prop="mealprice" label="套餐价格" width="100" />
    <el-table-column prop="storefront" label="购买店面" width="100" />
    <el-table-column prop="facereview" label="面审" width="100" />
    <el-table-column prop="orderratio" label="订单类型/押金比例" width="120" />
    <el-table-column prop="clienttype" label="客户类型" width="100">
      <template #default="scope">
        <span v-if="scope.row.clienttype == 1">新客户</span>
        <span v-if="scope.row.clienttype == 2">复购客户</span>
      </template>
    </el-table-column>
    <el-table-column label="身份证" width="220">
      <template #default="scope">
        <el-image
                  v-for="item in scope.row.id_card" :key="item.id"
                  style="width: 100px; height: 100px"
                  :src="item"
                  :zoom-rate="1.2"
                  :max-scale="7"
                  :min-scale="0.2"
                  :preview-src-list="scope.row.id_card"
                  :initial-index="4"
                  fit="cover" />
      </template>
    </el-table-column>
    <el-table-column prop="ywyname" label="业务员" width="100" />
    <el-table-column prop="addtime" label="提交时间" width="100" />
    <el-table-column prop="updatetime" label="审核时间" width="100" />
    <el-table-column fixed="right" :label="type == 0 ? '操作' : '审核结果'" min-width="120">
      <template #default="scope">
        <el-tag v-if="type == 0" style="cursor: pointer;" type="primary" size="small" @click="shenhe(scope.row)">
          审核
        </el-tag>
        <el-tag v-if="type == 0" style="cursor: pointer;margin-left: 10px;" type="danger" size="small" @click="del(scope.row, scope.$index)">
          删除
        </el-tag>
        <el-text v-else>
          <el-tag size="small" type="success" v-if="scope.row.examineresult == 1">通过</el-tag>
          <el-tag size="small" type="danger" v-else-if="scope.row.examineresult == 2">拒绝</el-tag>
          <el-tag size="small" type="warning" v-else-if="scope.row.examineresult == 3">提高押金</el-tag>
          <el-tag size="small" type="warning" v-else>{{ scope.row.textarea }}</el-tag>
          <div v-if="scope.row.file != null" style="margin-top: 10px;">
            <el-image
                      v-for="item in scope.row.file" :key="item"
                      style="width: 30px; height: 30px;padding: 0 2px;"
                      :src="item"
                      :zoom-rate="1.2"
                      :max-scale="7"
                      :min-scale="0.2"
                      :preview-src-list="scope.row.file"
                      :initial-index="4"
                      fit="cover" />
          </div>
        </el-text>
      </template>
    </el-table-column>
  </el-table>
  <view style="text-align: center;">
    <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="total" prev-text="上一页" next-text="下一页" />
  </view>
  <el-dialog
             v-model="centerDialogVisible"
             title="审核"
             width="30%"
             align-center>
    <el-radio-group v-model="pass" @change="change">
      <el-radio label="1">通过</el-radio>
      <el-radio label="2">拒绝</el-radio>
      <el-radio label="3">提高押金</el-radio>
      <el-radio label="4">自定义</el-radio>
    </el-radio-group>
    <el-input
              v-if="pass == 4"
              style="margin: 10px 0;"
              v-model="textarea"
              :rows="2"
              type="textarea"
              placeholder="请输入审核意见" />
    <el-upload
               v-if="pass == 4"
               v-model:file-list="fileList"
               action="https://xuanzj.xianniuzu.com/static/includes/data.php?op=uploadtempimg"
               list-type="picture-card"
               :on-success="handleSuccess"
               :on-preview="handlePreview"
               :on-remove="beforeRemove">
      <el-icon>
        <Plus />
      </el-icon>
    </el-upload>
    <el-dialog v-model="dialogVisible">
      <el-image
                preview-teleported
                :src="dialogImageUrl"
                :zoom-rate="1.2"
                :max-scale="7"
                :min-scale="0.2"
                :preview-src-list="fileList"
                :initial-index="4"
                fit="cover" />
    </el-dialog>
    <template #footer>
      <div class="dialog-footer">
        <el-button size="small" type="danger" @click="centerDialogVisible = false">取消</el-button>
        <el-button size="small" type="success" @click="save">确定</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref, reactive, onBeforeMount } from 'vue'
import { Plus } from '@element-plus/icons-vue'
import { ElMessage,ElMessageBox } from 'element-plus'
import constant from "@/constant";
import axios from 'axios';
const dialogImageUrl = ref('')
const dialogVisible = ref(false)
const textarea = ref('')
const loading = ref(true)
const activeName = ref('0')
const tableData = ref([]);
const fileList = ref([]);
const type = ref(0);
const pass = ref('')
const rows = ref([])
const ywy = ref([]);
const formInline = reactive({
    name: '',
    ywyid: '',
    date: '',
})
const total = ref(0)
const page = ref(1)
const centerDialogVisible = ref(false)
const del = (row, index) => {
  console.log(row, index)
  ElMessageBox.confirm(
    '确认删除？',
    '提示',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(() => {
      axios.post(constant.deletecs, { id: row.id }).then((response) => {
        console.log(response.data)
        if (response.data == 200) {
          ElMessage({
            message: '删除成功',
            type: 'success',
          })
          tableData.value.splice(index, 1);
        } else {
          ElMessage({
            message: '删除失败',
            type: 'error',
          })
        }
      })
    })
    .catch(() => {

    })

}
const handleClick = (tab) => {
    type.value = tab.props.name;
    fetchData(type, formInline, page);
}
const change = (val) => {
    pass.value = val;
}
const handleSuccess=(file)=>{
    console.log(file)
    dialogImageUrl.value = file.url
    fileList.value.push({url:file.url})
    console.log(fileList)
}
const handlePreview = (file) => {
    console.log(file)
    dialogVisible.value = true
}
const shenhe = (row) => {
    centerDialogVisible.value = true;
    rows.value = row;
}
const fetchData = (type, formInline, page) => {
    loading.value = true;
    axios.post(constant.read, { type: type.value, formInline: formInline, page: page.value }).then((res) => {
        console.log(res);
        setTimeout(() => {
            tableData.value = res.data.res;
            total.value = res.data.total;
            loading.value = false;
        }, 100);
    }).catch((err) => {
        console.log(err);
    });
};
const save = () => {
    if (pass.value == '') {
        ElMessage.error({
            message: '请选择操作结果',
            type: 'error',
            duration: 1500
        })
        return;
    }
    axios.post(constant.update, {
        id: rows.value.id,
        examineresult: pass.value,
        textarea: textarea.value,
        file: fileList.value,
    }).then((res) => {
        centerDialogVisible.value = false;
        if (res.data == 200) {
            ElMessage({
                message: '操作成功',
                type: 'success',
                duration: 1500
            })
            // 重新加载数据
            setTimeout(() => {
                fetchData(type, formInline, page);
            }, 1000);
        } else {
            ElMessage.error({
                message: '操作失败',
                type: 'error',
                duration: 1500
            })
        }
    }).catch((err) => {
        console.log(err);
    });
}

const onSubmit = () => {
    console.log(formInline)
    fetchData(type, formInline, page);
}
const listywy = () => {
    axios.post(constant.listywy).then((res) => {
        console.log(res);
        ywy.value = res.data;
    }).catch((err) => {
        console.log(err);
    });
}
const handleCurrentChange = (val) => {
    console.log(val,page.value)
    if (val != page.value) {
        page.value = val;
        fetchData(type, formInline, page);
    }
}
// 生命周期钩子
onBeforeMount(() => {
    fetchData(type, formInline, page);
    listywy();
});
</script>
<style>
.container .el-main {
    padding: 1.5rem;
}

.demo-tabs>.el-tabs__content {
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
}
</style>
